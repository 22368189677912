import React, { Component } from "react"
import { geolocated } from "react-geolocated";
import { getDistance } from "geolib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import StoreListItem from "./list-item"
import Empty from "../utils/empty"

class StoreList extends Component {
    state = {
        loadingLocation: false
    }

    constructor(props) {
        super(props)

        this.getLocation = this.getLocation.bind(this)
    }

    getLocation() {
        const { onGetLocation } = this.props

        if (typeof onGetLocation === "function" && !this.state.loadingLocation) {
            this.setState({ loadingLocation: true })
            onGetLocation()
        }
    }

    render() {
        let locationIcon

        const { stores, isGeolocationEnabled, coords, onGetLocation, segment } = this.props

        const storeList = [].concat(stores)
            .map(({ node }) => {
                if (!isGeolocationEnabled)
                    return { node }

                node.distance = null

                if (coords && node.acf.geo.latitude && node.acf.geo.longitude) {
                    node.distance = Math.round(getDistance(
                        { latitude: coords.latitude, longitude: coords.longitude },
                        { latitude: node.acf.geo.latitude, longitude: node.acf.geo.longitude }
                    ))
                }

                return { node }
            })
            .sort((a, b) => {
                a = a.node
                b = b.node

                if (isGeolocationEnabled) {
                    if ((a.distance && !b.distance) || (a.distance < b.distance))
                        return -1

                    if ((!a.distance && b.distance) || (a.distance > b.distance))
                        return 1
                }

                return 0;
            })

            if (this.state.loadingLocation)
                locationIcon = <FontAwesomeIcon icon={["fal", "spinner"]} spin />
            else {
                locationIcon = <FontAwesomeIcon icon={["fal", "location"]} />
            }

        if (!storeList.length) {
            return (
                <Empty>Wir konnten leider {segment ? `Geschäfte in dieser Kategorie` : "Geschäfte"} finden.</Empty>
            )
        }

        return (
            <div className="relative">
                {onGetLocation && !isGeolocationEnabled &&
                <button className="outline-none absolute text-lg right-0 top-0 border text-gray-600 border-gray-300 rounded-full w-10 h-10 bg-white shadow-lg" onClick={this.getLocation} aria-label="Aktuellen Standort bestimmen">
                    {locationIcon}
                    <span className="screen-reader-text">Standort bestimmen</span>
                </button>
                }
                <ul>
                    {storeList.map(({ node }) => (
                        <StoreListItem key={node.id} store={node} />
                    ))}
                </ul>
            </div>
        )
    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
        watchPosition: false,
    },
    suppressLocationOnMount: true,
    userDecisionTimeout: 5000,
    isOptimisticGeolocationEnabled: false,
}) (StoreList);
