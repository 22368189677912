import React, { Component } from "react"
import moment from "moment"
import { Link } from "gatsby"
import { convertDistance } from "geolib"
import Img from "gatsby-image"
import Avatar from "../utils/avatar"

const weekDays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]

class StoreListItem extends Component {
    state = {
        opened: false
    }

    componentDidMount() {
        this.fetchOpeningHours()
    }

    render() {
        const { store } = this.props
        const { opened } = this.state
        const { featured_media } = store

        let distance = false

        if (store.distance) {
            if (store.distance > 1000)
                distance = Math.round(convertDistance(store.distance, 'km')) + ' km'
            else
                distance = store.distance + ' m'
        }

        return (
            <li className="list-item">
                <Link to={`/stores/${store.slug}`} className="flex w-full py-3 link">
                    <Avatar className="flex-none w-16 ml-0 mr-sm">
                        {featured_media && featured_media.localFile && featured_media.localFile.childImageSharp &&
                            <Img
                                fixed={featured_media.localFile.childImageSharp.fixed}
                                alt={store.title}
                                />
                        }
                    </Avatar>
                    <div className="py-2 pr-2">
                        <h3 className="my-0 text-inherit" dangerouslySetInnerHTML={{ __html: store.title }} />
                        <div>{store.acf.address}</div>
                        <ul className="meta">
                            {opened && <li>{opened}</li>}
                            {distance && <li>{distance}</li>}
                        </ul>
                    </div>
                </Link>
            </li>
        )
    }

    fetchOpeningHours = () => {
        const self = this
        const weekday = weekDays[moment().weekday()]
        const format = 'hh:mm'

        let isOpened = false

        const { store : {
            acf: { no_openings, opening_hours }
        } } = this.props

        self.setState({ opened: false })

        if (opening_hours && weekday in opening_hours) {
            const today = opening_hours[weekday]

            if (today.opened) {
                for (const opened of today.time) {

                    const now = moment()
                    const from = moment(opened.from, format)
                    const to = moment(opened.to, format)

                    if (now.isBetween(from, to)) {
                        let hours = to.diff(now, 'hours', true)
                        let output

                        if (hours < 1) {
                            const minutes = to.diff(now, 'minutes')
                            output = minutes === 1 ? "eine Minute" : `${minutes} Minuten`
                        } else {
                            hours = Math.round(hours)
                            output = hours === 1 ? "etwa eine Stunde" : `${hours} Stunden`
                        }

                        self.setState({ opened: `noch ${output} geöffnet` })

                        isOpened = true
                        break;
                    }
                }
            }

            if (no_openings) {
                self.setState({ opened: null })
            } else if (!isOpened) {
                self.setState({ opened: 'jetzt geschlossen' })
            }
        }
    }
}

export default StoreListItem
